import * as React from "react"
import scrollTo from 'gatsby-plugin-smoothscroll';
import { Header } from '../components/Header_Black';
import { Footer } from '../components/Footer';
import { Meta } from '../components/Meta';

// markup
const JoinOurTeamPage = () => {
  return (
    <main className="font-body text-black mx-auto lg:min-w-full">
      <Meta title="Join Our Team Page" bodyClasses="" />
      <Header /><br/>
      <section className="headline-section max-w-3xl mx-auto sm:mx-4 mt-24 bg-[#f5f5f7] rounded-[24px]">
        <div className="text-center pt-12 sm:pt-8 pb-12 sm:pb-8">
          <div className="font-Medium text-4xl sm:text-3xl">
            <div className="">Join our team and</div>
            <div className="text-gradient-bluegreen">inspire the work.</div>
          </div>
          <div className="pt-5 sm:pt-6 text-lg sm:text-sm px-24 sm:px-8">See hot recruitment vacancies and opportunities at Media Max Japan Vietnam.</div>
          <div className="">
            <button className="pt-4 sm:pt-6 flex flex-row items-center mx-auto" onClick={() => scrollTo('#openning-position')} >
              <div className="text-tealish text-mid sm:text-sm font-Medium">View opening positions</div>
              <img className="pl-2" src="../../ic-arrow-right.png"/>            
            </button>
          </div>
        </div>
      </section>
      <section className="benefit-section bg-[#faf5ef] pt-20 sm:pt-16 mt-20 sm:mt-12">
        <div className="flex flex-wrap mx-auto justify-between flex-row max-w-5xl sm:px-4">
          <div className="flex flex-col w-9/20 sm:w-full sm:pb-12 sm+:sticky sm+:top-10 sm+:self-start">
            <div className="text-[#bc9b50] font-Bold text-4xl">Benefits that you’ll enjoy…</div>
            <div className="text-nice text-sm pt-8 sm:pt-4">While we work as a team, each individual matters. Here you’ll find a home where you are yourself, with flexibility to work in a trusted and caring environment.</div>
          </div>
          <div className="flex flex-col w-9/20 sm:w-full">
            <div className="">
              <div className="text-4xl">❤️</div>
              <div className="pt-2 text-3xl sm:text-md font-Medium">Work — Life Balance</div>
              <div className="pt-4 text-sm sm:text-base">40-hour work week.</div>
              <div className="pt-4 text-sm sm:text-base">We don’t work overtime or weekends. We respect our people’s private time and encourage them to have quality time with family and for themselves.</div>
              <div className="pt-4 text-sm sm:text-base">Flexible working hours are well applied so that we can make work — life balance.</div>
              <div className="pt-4 text-sm sm:text-base">We don’t have many policies at work as it is difficult to remember and inflexible. We decide things based on our values, data and logical analysis. That helps us be more confident with our decisions and everyone understands the reasons behind.</div>
            </div>
            <div className="pt-12">
              <div className="text-4xl">🚀</div>
              <div className="pt-2 text-3xl sm:text-md font-Medium">Growth</div>
              <div className="pt-4 text-sm sm:text-base">As a flat company, we don’t have Career Ladder. It seems that MMJers do not care much about this.</div>
              <div className="pt-4 text-sm sm:text-base">Our definition of Career Path is your progression in your skills, the ability to solve difficult problems and more difficult problems, the better Emotional Intelligence.</div>
              <div className="pt-4 text-sm sm:text-base">In MMJ, we exchange knowledge, provide feedbacks, give you the chance to try, the things that are totally new to you… as we believe this is the right way to move forward.</div>
            </div>
            <div className="pt-12">
              <div className="text-4xl">🖥️</div>
              <div className="pt-2 text-3xl sm:text-md font-Medium">Workspace</div>
              <div className="pt-4 text-sm sm:text-base">Open workspace and meeting area to ensure that information is transparent and accessed by everyone. We use wide tables and provide high-speed computers for everyone.</div>
            </div>
            <div className="pt-12">
              <div className="text-4xl">🏆</div>
              <div className="pt-2 text-3xl sm:text-md font-Medium">Ownership and Impact</div>
              <div className="pt-4 text-sm sm:text-base">We will be able to gain experience from the entire process of development, both business and technical, work on high-impact things and be proud to say that “I develop this.”</div>
            </div>
          </div>
        </div>
        <img className="object-contain object-center mx-auto w-full max-w-[968px] sm:hidden pt-16 pb-20" src="../../group-7.png"/>
        <div className="mx-4 pt-16 sm:pt-12 pb-20 sm:pb-12">
          <img className="object-contain object-center mx-auto sm+:hidden" src="../../group-7-mobile.png"/>
        </div>
      </section>
      <section className="process-section max-w-3xl mx-auto pb-20">
        <div className="text-gradient-red text-center font-Bold pt-24 pb-20 text-6xl sm:text-[36px]">Recruitment Process</div>
        <div className="flex flex-col sm:mx-8">
          <div className="flex flex-row flex-wrap justify-between sm:flex-col">
            <div className="w-2/5 sm:w-full">
              <div className="shadow-light w-20 h-20 rounded-2xl flex justify-center items-center mx-auto">
                <img className="object-contain max-w-[56px]" src="../../bitmap.png"/>
              </div>
              <div className="pt-6 font-Bold text-lg sm:text-md text-center">1st Meeting</div>
              <div className="pt-3 text-nice text-center ">Culture is important to us. Culture fit increases hiring success. We use the whole conversation to know more about you, personalities, learning ability and motivation.</div>
            </div>
            <img className="self-start sm:hidden" src="../../group-6.png"/>
            <img className="flex self-center pt-6 pb-8 sm+:hidden" src="../../group-6-copy-2.png"/>
            <div className="w-2/5 sm:w-full">
              <div className="shadow-light w-20 h-20 rounded-2xl flex justify-center items-center mx-auto">
                <img className="object-contain max-w-[56px]" src="../../bitmap_3.png"/>
              </div>
              <div className="pt-6 font-Bold text-lg sm:text-md text-center">2nd Visit</div>
              <div className="pt-3 text-nice text-center">If you’re invited to the next round, that means we already like you a lot. You’ll meet our developers and maybe more technical questions will be asked.</div>
            </div>
          </div>
          <div className="flex flex-row-reverse sm:self-center pt-6 pb-8">
            <img className="relative left-[-15%] sm:static" src="../../group-6-copy-2.png"/>
          </div>
          <div className="flex flex-row-reverse flex-wrap justify-between sm:flex-col">
            <div className="w-2/5 sm:w-full">
              <div className="shadow-light w-20 h-20 rounded-2xl flex justify-center items-center mx-auto">
                <img className="object-contain max-w-[48px]" src="../../bitmap_4.png"/>
              </div>
              <div className="pt-6 font-Bold text-lg sm:text-md text-center">Technical Test</div>
              <div className="pt-3 text-nice text-center">Case by case, we would like you to join a test (at home) or a mob programming (in office) to understand more about our candidates.</div>
            </div>
            <img className="self-start sm:hidden" src="../../group-6-copy.png"/>
            <img className="self-center pt-6 pb-8 sm+:hidden" src="../../group-6-copy-2.png"/>
            <div className="w-2/5 sm:w-full">
              <div className="shadow-light w-20 h-20 rounded-2xl flex justify-center items-center mx-auto">
                <img className="object-contain max-w-[48px]" src="../../bitmap_2.png"/>
              </div>
              <div className="pt-6 font-Bold text-lg sm:text-md text-center">What We Look For</div>
              <div className="pt-3 text-nice text-center">Open & Clear communication and Culture & Skills fits</div>
            </div>
          </div>
        </div>
      </section>
      <section className="open-posi-section pb-24 " id="openning-position">
        <div className="bg-yellowGradient">
          <div className="max-w-4xl mx-auto font-Bold text-4xl sm:text-lg pt-[100px] pb-[142px] sm:text-center ">Open Positions</div>
        </div>
        <div className="max-w-4xl mx-auto sm:mx-4 text-lg sm:text-mid relative top-[-62px] ">
          <div className="shadow-middle hover:shadow-darker delay-75 hover:-translate-y-1 p-6 rounded-xl bg-white">
            <a href="https://docs.google.com/document/d/1GspZQnHC9CGLy7nwN-Ey1I7B5nj_2AXX/edit?usp=sharing&ouid=118185055242589589984&rtpof=true&sd=true" target={"_blank"}>
            Front End Developer (HTML/CSS)
            </a>
          </div>
        </div>
      </section>
      <Footer />
    </main>
)   
}
export default JoinOurTeamPage